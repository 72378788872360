import imageOr from "../assets/images/zakat/or.svg";
import imageMoney from "../assets/images/zakat/money.svg";
import imageCamel from "../assets/images/zakat/camel.svg";
import imageCereals from "../assets/images/zakat/cereals.png";
import imageArgent from "../assets/images/zakat/argent.png";
import imageCattle from "../assets/images/zakat/cattle.png";
import imageSheep from "../assets/images/zakat/sheep.png";
import imageDate from "../assets/images/zakat/date.png";
import imageRaisin from "../assets/images/zakat/raisin.png";

export const PRAYER_LIST = [
  "Fajr",
  "Sunrise",
  "Dhuhr",
  "Asr",
  "Maghrib",
  "Isha",
];

export const LIST_SHEIKH = [
  {
    name: "",
    description: "",
  },
];

export const LIST_RECITERS = [
  { label: "Alafasy", label_ar: "العفاسي", value: "ar.alafasy" },
  {
    label: "Abd Elbaset Abd Samad",
    label_ar: "عبد الباسط عبد الصمد",
    value: "ar.abdulbasitmujawwad",
  },
  {
    label: "Ali Abd Elrahman Alhudhayfi",
    label_ar: "علي عبد الرحمن الحذيفي",
    value: "ar.aliabdurrahmanalhuthaify",
  },
  {
    label: "Abdullah Basfar",
    label_ar: "عبد الله بصفر",
    value: "ar.abdullahbasfar",
  },
];

export const GHOUSL_STEPS = [
  {
    title: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_1_TITLE",
    description: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_1_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_2_TITLE",
    description: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_2_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_3_TITLE",
    description: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_3_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_4_TITLE",
    description: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_4_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_5_TITLE",
    description: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_5_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_6_TITLE",
    description: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_6_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_7_TITLE",
    description: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_7_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_8_TITLE",
    description: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_8_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_9_TITLE",
    description: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_9_DESCRIPTION",
  },
];

export const LIST_POSSESSIONS_ZAKAT = [
  {
    value: "or_argent",
    name: "zakat.OR_ARGENT",
    description: "zakat.OR_ARGENT_DESCRIPTION",
    image: imageOr,
    unit: "zakat.G",
    list: [
      {
        value: "or",
        name: "zakat.OR",
        image: imageOr,
        description: "zakat.OR_DESCRIPTION",
        amount: 0,
        nisab: 85,
      },
      {
        value: "argent",
        name: "zakat.ARGENT",
        image: imageArgent,
        description: "zakat.ARGENT_DESCRIPTION",
        amount: 0,
        nisab: 595,
      },
    ],
  },
  {
    value: "cache",
    name: "zakat.MONEY",
    description: "zakat.MONEY_DESCRIPTION",
    image: imageMoney,
    unit: "zakat.TND",
    list: [
      {
        value: "encaisse",
        name: "zakat.CASH",
        description: "zakat.MONEY_DESCRIPTION_2",
        amount: 0,
      },
      {
        value: "especes_bancaire",
        name: "zakat.CASH_BANK",
        description: "zakat.MONEY_DESCRIPTION_2",
        amount: 0,
      },
    ],
  },
  {
    value: "livestock",
    name: "zakat.LIVESTOCK",
    description: "zakat.LIVESTOCK_DESCRIPTION",
    image: imageCamel,
    unit: "zakat.QTE",
    list: [
      {
        value: "cattle",
        name: "zakat.CATTLE",
        image: imageCattle,
        description: "zakat.CATTLE_DESCRIPTION",
        amount: 0,
        nisab: 30,
      },
      {
        value: "camel",
        name: "zakat.CAMEL",
        image: imageCamel,
        description: "zakat.CAMEL_DESCRIPTION",
        amount: 0,
        nisab: 5,
      },
      {
        value: "sheep",
        name: "zakat.SHEEP",
        image: imageSheep,
        description: "zakat.SHEEP_DESCRIPTION",
        amount: 0,
        nisab: 40,
      },
    ],
  },
  {
    value: "agriculture",
    name: "zakat.AGRICULTURE",
    description: "zakat.AGRICULTURE_DESCRIPTION",
    image: imageCereals,
    unit: "zakat.KG",
    list: [
      {
        value: "date",
        name: "zakat.DATE",
        description: "zakat.AGRICULTURE_DESCRIPTION_2",
        image: imageDate,
        amount: 0,
        nisab: 653,
        type: "irrigated",
      },
      {
        value: "raisin",
        name: "zakat.RAISIN",
        description: "zakat.AGRICULTURE_DESCRIPTION_2",
        image: imageRaisin,
        amount: 0,
        nisab: 653,
        type: "irrigated",
      },
      {
        value: "cereals",
        name: "zakat.CEREALS",
        description: "zakat.AGRICULTURE_DESCRIPTION_2",
        image: imageCereals,
        amount: 0,
        nisab: 653,
        type: "irrigated",
      },
    ],
  },
];

import React, { useState, useEffect } from "react";
import { Wheel } from "react-custom-roulette";
import "./Quiz.scss";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import flecheQuiz from "../../assets/logos/fleche-quiz.svg";
import questionSquare from "../../assets/logos/question-square.svg";
import confetti from "canvas-confetti";
import Footer from "../../components/layout/Footer.jsx";
import Amazing from "../../assets/logos/amazing.svg";
import Nice from "../../assets/logos/Nice.svg";
import Great from "../../assets/logos/great.svg";
import Done from "../../assets/logos/done.svg";
import NotBad from "../../assets/logos/notBad.svg";
import Good from "../../assets/logos/goodeffort.svg";
import Tryagain from "../../assets/logos/tryagain.svg";
import { getQuestionsQuizByCategory } from "../../services/common.service.ts";

const Quiz = () => {
  const { t, i18n } = useTranslation();

  const QuizData = [
    {
      option: t("Quiz.HISTORY"),
      style: { backgroundColor: "#406259" },
      category: "histoire",
    },
    {
      option: t("Quiz.CORAN"),
      style: { backgroundColor: "#609486" },
      category: "coran",
    },
    {
      option: t("Quiz.PROPHETS"),
      style: { backgroundColor: "#8FBCB0" },
      category: "prophets",
    },
    {
      option: t("Quiz.MOHAMED"),
      style: { backgroundColor: "#D8D9C4" },
      category: "Mohamed",
    },
    {
      option: t("Quiz.CULTURE"),
      style: { backgroundColor: "#C5BC99" },
      category: "culture",
    },
    {
      option: t("Quiz.COMPAGNONS"),
      style: { backgroundColor: "#BAAF84" },
      category: "compagnons",
    },
    {
      option: t("Quiz.JURISPRUDENCE"),
      style: { backgroundColor: "#1D342E" },
      category: "jurisprudence",
    },
  ];

  const isArabic = i18n.language === "ar";
  const [toastId, setToastId] = useState(null);
  const [category, setCategory] = useState("");

  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [showQuiz, setShowQuiz] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [selectedOption, setSelectedOption] = useState("");
  const [answerChecked, setAnswerChecked] = useState(false);
  const [score, setScore] = useState(0);
  const [quizFinished, setQuizFinished] = useState(false);
  const [QuestionOfQuizData, setQuestionOfQuizData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (category) {
      setLoading(true);
      getQuestionsQuizByCategory(category)
        .then((result) => {
          setQuestionOfQuizData(result);
          setLoading(false);
        })
        .catch((_err) => {
          setLoading(false);
        });
    }
  }, [category]);

  const handleWheelClick = () => {
    if (!mustSpin) {
      const newPrizeNumber = Math.floor(Math.random() * QuizData.length);

      setPrizeNumber(newPrizeNumber);
      setCategory(
        newPrizeNumber !== 0
          ? QuizData[newPrizeNumber - 1].category
          : QuizData[QuizData?.length - 1].category
      );
      setMustSpin(true);
    }
  };

  const handleWheelStop = (ev) => {
    setMustSpin(false);
    setShowQuiz(true);
  };

  const handleOptionChange = (option) => {
    if (!answerChecked) {
      setSelectedOption(option);
    }
  };

  const handleSubmitOrNext = () => {
    if (!selectedOption && !answerChecked) {
      if (toastId) {
        toast.dismiss(toastId);
      }

      const id = toast.error(t("Quiz.SELECT_REPONSE"));
      setToastId(id);
      return;
    }

    if (answerChecked) {
      if (currentQuestion < QuestionOfQuizData.length) {
        setCurrentQuestion(currentQuestion + 1);
        setSelectedOption("");
        setAnswerChecked(false);
      } else {
        setQuizFinished(true);
      }
    } else {
      if (selectedOption) {
        setAnswerChecked(true);
        const correctAnswer = QuestionOfQuizData[
          currentQuestion - 1
        ].choices.find((choice) => choice.correct);
        if (selectedOption === correctAnswer[i18n.language]) {
          setScore(score + 1);
        }
      }
    }
  };

  // animation
  useEffect(() => {
    const percentage = (score / QuestionOfQuizData.length) * 100;

    if (quizFinished && percentage >= 50) {
      const defaults = {
        spread: 360,
        ticks: 50,
        gravity: 0,
        decay: 0.94,
        startVelocity: 30,
        colors: ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
      };

      function shoot() {
        confetti({
          ...defaults,
          particleCount: 40,
          scalar: 1.2,
          shapes: ["star"],
        });

        confetti({
          ...defaults,
          particleCount: 10,
          scalar: 0.75,
          shapes: ["circle"],
        });
      }
      [
        30, 230, 400, 500, 600, 700, 800, 900, 1000, 1100, 1300, 1600, 2000,
        2400, 2800, 3100, 3300, 3500, 3800, 4100, 4400, 4800, 5000, 5200, 5400,
        5700, 6000, 6200,
      ].forEach((item) => {
        setTimeout(shoot, item);
      });
    }
  }, [quizFinished, score]);

  return (
    <div className="pages-position quiz">
      <div className="pages-header">
        <span className="title-page">{t("Quiz.TITLE")}</span>
      </div>
      {quizFinished ? (
        <ResultQuiz
          score={score}
          QuestionOfQuizData={QuestionOfQuizData}
        ></ResultQuiz>
      ) : !showQuiz ? (
        <div className="quiz-body">
          <div className="quiz-text">
            <p>{t("Quiz.READY_TEXT")}</p>
          </div>
          <div className="wheel-container">
            <div className="button-turn-container">
              <button className="button-turn" onClick={handleWheelClick}>
                {t("Quiz.ButtonTourne")}
              </button>
            </div>
            <Wheel
              className="wheel"
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={QuizData}
              outerBorderColor={"#f2f2f2"}
              outerBorderWidth={4}
              innerBorderColor={"#D9D9D9"}
              radiusLineColor={"#dedede"}
              radiusLineWidth={1}
              fontSize={17}
              textColors={["#FFFFFF"]}
              onStopSpinning={(ev) => handleWheelStop(ev)}
              spinDuration={0.8}
              pointerProps={{ src: flecheQuiz }}
            />
          </div>
        </div>
      ) : (
        // Quiz avec QuestionOfQuizData

        <div className="questionner-container">
          <div className="header">
            <span
              className={`number-question-bar ${
                isArabic ? "arabic-direction" : ""
              }`}
            >
              {isArabic
                ? `س ${QuestionOfQuizData.length}/${currentQuestion}`
                : `Q ${currentQuestion}/${QuestionOfQuizData.length}`}
            </span>
            <div className="progress-bar">
              <div
                className="progress"
                style={{
                  width: `${
                    (currentQuestion / QuestionOfQuizData.length) * 100
                  }%`,
                }}
              ></div>
            </div>
          </div>
          <div className="image-container">
            <img
              src={questionSquare}
              alt="Question"
              className="image-ofQuestion"
            />
          </div>
          <div className="options-questionner">
            <h2>
              {QuestionOfQuizData[currentQuestion - 1]?.question[i18n.language]}
            </h2>
            {QuestionOfQuizData[currentQuestion - 1]?.choices.map(
              (option, index) => (
                <button
                  key={index}
                  onClick={() => handleOptionChange(option[i18n.language])}
                  className={`option-button ${
                    selectedOption === option[i18n.language] ? "selected" : ""
                  } ${
                    answerChecked
                      ? option.correct
                        ? "correct"
                        : option[i18n.language] === selectedOption
                        ? "incorrect"
                        : ""
                      : ""
                  }`}
                  disabled={answerChecked}
                >
                  {option[i18n.language]}
                </button>
              )
            )}
          </div>

          <div className="h-14">
            <button
              className="submit-button"
              onClick={handleSubmitOrNext}
              disabled={false}
            >
              {answerChecked && currentQuestion < QuestionOfQuizData.length
                ? t("Quiz.NEXT")
                : answerChecked
                ? t("Quiz.FINISH")
                : t("Quiz.SUBMIT")}
            </button>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

const ResultQuiz = ({ score, QuestionOfQuizData }) => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const percentage = (score / QuestionOfQuizData.length) * 100;

  const getMessageAndIcon = () => {
    if (percentage >= 90) return { message: t("Quiz.AMAZING"), icon: Amazing };
    if (percentage >= 80) return { message: t("Quiz.NICE_WORK"), icon: Nice };
    if (percentage >= 70) return { message: t("Quiz.GREAT"), icon: Great };
    if (percentage >= 50) return { message: t("Quiz.WELL_DONE"), icon: Done };
    if (percentage >= 40) return { message: t("Quiz.NOT_BAD"), icon: NotBad };
    if (percentage >= 20) return { message: t("Quiz.GOOD_EFFORT"), icon: Good };
    return { message: t("Quiz.TRY_AGAIN"), icon: Tryagain };
  };
  const { message, icon } = getMessageAndIcon();

  return (
    <div className="quiz-finished-container quiz">
      <div className="image-score-container">
        <img src={questionSquare} alt="" className="image-score" />
        <div className="quiz-finished-overlay">
          <h1 className="score-message">{message}</h1>
          <div className="score">
            <img src={icon} alt="icon-score" className="icon" />
            <p className="YourScore">
              {t("Quiz.SCORE")}{" "}
              {isArabic
                ? `${score} / ${QuestionOfQuizData.length}`
                : `${score} / ${QuestionOfQuizData.length}`}
            </p>
            <button
              className={`play-again ${isArabic ? "arabic" : ""}`}
              onClick={() => window.location.reload()}
            >
              {t("Quiz.PLAY_AGAIN")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quiz;
